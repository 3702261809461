<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Order the following intermolecular interactions from <b>strongest</b> down to
        <b>weakest</b>:
      </p>

      <drag-to-rank-input
        v-model="inputs.input1"
        :items="items"
        class="mb-0"
        style="max-width: 512px"
        prepend-text="Strongest"
        append-text="Weakest"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import DragToRankInput from '@/tasks/components/inputs/DragToRankInput.vue';

export default {
  name: 'ChemCPP1210S4_Q9',
  components: {
    DragToRankInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: [],
      },
      items: ['hydrogen bonds', 'ion-dipole', 'London dispersion forces'],
    };
  },
};
</script>
